import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, TextField, Typography, Grid, Button, Box, CssBaseline,
  Card, CardContent, CardActions, InputAdornment, IconButton, CircularProgress
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { motion } from 'framer-motion';
import Autosuggest from 'react-autosuggest';
import { FaSteam } from 'react-icons/fa';
import { MdGames } from 'react-icons/md';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1b74e4',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      default: '#121212',
      paper: '#1c1c1c',
    },
    text: {
      primary: '#ffffff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const App = () => {
  const [games, setGames] = useState([]);
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const [steamId, setSteamId] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const authenticateWithSteam = () => {
    window.location.href = 'https://achievements-api.patricknj.dev/auth/steam';
  };

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get('https://achievements-api.patricknj.dev/auth/steam/auth/steam/callback');
        if (response.data.steamId) {
          setIsAuthenticated(true);
          setSteamId(response.data.steamId);
          setGames(response.data.games);
          setFilteredGames(response.data.games);
        }
      } catch (error) {
        console.error('Error fetching games:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : games.filter(game =>
      game.name.toLowerCase().includes(inputValue)
    );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (event, { newValue }) => {
    setSearch(newValue);
    setFilteredGames(getSuggestions(newValue));
  };

  const inputProps = {
    placeholder: "Search games...",
    value: search,
    onChange: onChange
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box textAlign="center" py={5}>
          <Typography variant="h2" component="h1" gutterBottom>
            <FaSteam /> Steam Achievements
          </Typography>
          {!isAuthenticated && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50vh"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={authenticateWithSteam}
              >
                Login with Steam
              </Button>
            </Box>
          )}
          {loading && !isAuthenticated && (
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
              <CircularProgress color="primary" />
            </Box>
          )}
        </Box>
        {isAuthenticated && !loading && (
          <>
            <Box mb={6} display="flex" justifyContent="center">
              <Box width={{ xs: '100%', sm: '50%' }}>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={(suggestion) => suggestion.name}
                  renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
                  inputProps={inputProps}
                  renderInputComponent={(inputProps) => (
                    <TextField
                      {...inputProps}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        ...inputProps.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton edge="start">
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Grid container spacing={4}>
              {filteredGames.map((game) => (
                <Grid item key={game.appid} xs={12} sm={6} md={4}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Card>
                      <CardContent>
                        <Box display="flex" alignItems="center" mb={2}>
                          <MdGames fontSize="large" />
                          <Typography variant="h5" component="h2" ml={2}>
                            {game.name}
                          </Typography>
                        </Box>
                        <Typography color="textSecondary">
                          Playtime: {Math.floor(game.playtime_forever / 60)} hrs
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="primary"
                          fullWidth
                          onClick={() => window.location.href = `https://achievements-api.patricknj.dev/auth/steam/api/achievements/${steamId}/${game.appid}`}
                        >
                          View Achievements
                        </Button>
                      </CardActions>
                    </Card>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default App;
